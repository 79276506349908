* {
  margin: 0;
  padding: 0; }

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  transition: all 0.3s ease-in-out; }

img,
iframe,
video {
  max-width: 100%; }

table {
  width: 100%; }

img {
  border: none;
  max-width: 100%;
  height: auto; }

ol,
ul {
  list-style: none; }

fieldset {
  border: 2px groove threedface;
  display: block;
  padding-bottom: 0.75em;
  padding-top: 0.35em;
  text-align: center; }

fieldset legend {
  display: inline-block;
  color: inherit;
  background: none;
  vertical-align: top; }

::-webkit-selection {
  background: #47c7ff;
  color: #ffffff; }

::-moz-selection {
  background: #47c7ff;
  color: #ffffff; }

::selection {
  background: #47c7ff;
  color: #ffffff; }

::-webkit-input-placeholder {
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

input::-moz-placeholder {
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

input::ms-input-placeholder {
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

[placeholder]:focus::-webkit-input-placeholder {
  text-indent: 10em;
  color: transparent; }

[placeholder]:focus::-moz-placeholder {
  text-indent: 10em;
  color: transparent; }

body,
html {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #212121;
  background: #ffffff;
  font-size: 16px;
  overflow-x: hidden; }

.title {
  font-family: "MonumentExtended", cursive; }

.color-main {
  color: #081cdd; }

.form-control,
button,
input,
select {
  transition: all 0.3s ease-in-out;
  outline: none !important;
  box-shadow: none;
  cursor: pointer; }
  .form-control:hover, .form-control:focus, .form-control:active,
  button:hover,
  button:focus,
  button:active,
  input:hover,
  input:focus,
  input:active,
  select:hover,
  select:focus,
  select:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #081cdd; }

/*custom Scroll Chrome */
::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(105, 105, 105, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(105, 105, 105, 0.4); }

/* Bootstrap Overrides */
@media (min-width: 1024px) {
  .container {
    max-width: 1024px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

@media (min-width: 992px) {
  .col-lg-5th {
    flex: 0 0 20%;
    max-width: 20%; } }

body {
  min-height: 100vh; }

.timeline__center {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 120px;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%); }
  .timeline__center span {
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "MonumentExtended"; }

.timeline__bottom {
  position: absolute;
  bottom: 0;
  right: 120px;
  height: calc(50vh - 50px);
  width: 1px;
  background: #ffffff; }

.timeline__top {
  position: absolute;
  top: 0;
  right: 120px;
  height: calc(50vh - 50px);
  width: 1px;
  background: #ffffff; }

.page__slide .fp-controlArrow {
  display: none; }

.page__slide .slide {
  position: relative; }

.page__loader {
  position: fixed;
  z-index: 120;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #00eaff;
  display: flex;
  align-items: center;
  justify-content: center; }

:root {
  --animate-duration: 0.5s;
  --animate-delay: 0.5s;
  --animate-repeat: 1; }

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: 0.5s;
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animate__animated.animate__slow {
  -webkit-animation-duration: calc(1s * 2);
  animation-duration: calc(1s * 2);
  -webkit-animation-duration: calc(0.5s * 2);
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(0.5s * 2);
  animation-duration: calc(var(--animate-duration) * 2); }

.animate__animated.animate__slower {
  -webkit-animation-duration: calc(1s * 3);
  animation-duration: calc(1s * 3);
  -webkit-animation-duration: calc(0.5s * 3);
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(0.5s * 3);
  animation-duration: calc(var(--animate-duration) * 3); }

.animate__animated.animate__delay-1s {
  -webkit-animation-delay: calc(0.5s * 1);
  -webkit-animation-delay: calc(var(--animate-delay) * 1);
  animation-delay: calc(0.5s * 1);
  animation-delay: calc(var(--animate-delay) * 1); }

.animate__animated.animate__delay-1s2 {
  -webkit-animation-delay: calc(0.5s * 1.2);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.2);
  animation-delay: calc(0.5s * 1.2);
  animation-delay: calc(var(--animate-delay) * 1.2); }

.animate__animated.animate__delay-1s4 {
  -webkit-animation-delay: calc(0.5s * 1.4);
  -webkit-animation-delay: calc(var(--animate-delay) * 1.4);
  animation-delay: calc(0.5s * 1.4);
  animation-delay: calc(var(--animate-delay) * 1.4); }

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important; }
  .animate__animated[class*="Out"] {
    opacity: 0; } }

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05); }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02); } }

@keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05); }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02); } }

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@font-face {
  font-family: "MonumentExtended";
  src: url("/public/font/MonumentExtended-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "MonumentExtended";
  src: url("/public/font/MonumentExtended-Ultrabold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

.project_detail {
  background: #cff3f3;
  min-height: 100vh; }
  .project_detail__modal {
    background: #ffffff;
    padding-left: 0 !important; }
    .project_detail__modal .modal-dialog {
      max-width: 100%;
      margin: 0; }
    .project_detail__modal .modal-content {
      border: none;
      border-radius: 0; }
    .project_detail__modal .modal-body {
      padding: 0; }
  .project_detail__container {
    display: flex;
    width: 100%;
    min-height: 100vh; }
  .project_detail__info {
    width: 100%;
    max-width: 515px;
    background: #5bcdcd;
    padding-right: 180px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .project_detail__info .content {
      max-width: 295px; }
  .project_detail__imgs {
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding-top: 67px;
    background: url(/public/img/graphic_project_detail_bg.jpg) no-repeat;
    background-position: center center;
    background-size: cover; }
  .project_detail__item {
    max-width: 696px;
    display: block;
    margin: auto;
    margin-bottom: 30px; }
    .project_detail__item img {
      border-radius: 5px; }
  .project_detail__name {
    margin-top: 15px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.07em;
    color: #6a6e76; }
    .project_detail__name .number {
      font-family: "MonumentExtended";
      margin-right: 5px; }
    .project_detail__name .device {
      margin-right: 5px; }
  @media (max-width: 991px) {
    .project_detail__container {
      display: block; }
    .project_detail__timeline {
      display: none; }
    .project_detail__info {
      padding-right: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: none;
      max-width: initial; }
      .project_detail__info .content {
        width: 100%;
        max-width: none;
        max-width: initial; }
      .project_detail__info .graphic_project__des {
        display: none; }
      .project_detail__info .graphic_project__cta {
        width: 100%;
        height: 65px; }
    .project_detail__imgs {
      padding: 15px;
      padding-bottom: 70px; } }

.banner_intro {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url("/public/img/background__landingapage.jpg") no-repeat;
  background-position: center;
  background-size: cover; }
  .banner_intro__canvas {
    display: block;
    width: 100%;
    height: 100vh;
    background: #000000;
    max-width: 100%; }
  .banner_intro__text {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 10px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #ffffff;
    font-size: 13px;
    line-height: 1.3;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: "MonumentExtended"; }
    .banner_intro__text .icon {
      margin-left: 5px; }
  .banner_intro__title {
    position: absolute;
    top: calc(30% - 30px);
    left: 120px;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: 0.3em;
    font-family: "MonumentExtended";
    color: #ffffff; }
  .banner_intro__sub {
    position: absolute;
    bottom: 30%;
    left: 120px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: 0.07em;
    color: #ffffff;
    max-width: 582px; }
  @media (max-width: 991px) {
    .banner_intro {
      padding-bottom: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 15px;
      padding-right: 15px; }
      .banner_intro__container {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px; }
      .banner_intro__canvas {
        display: none; }
      .banner_intro__timeline .timeline__bottom {
        height: 40px;
        bottom: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .banner_intro__timeline .timeline__center {
        right: 50%;
        bottom: 50px;
        top: auto;
        -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0); }
      .banner_intro__title {
        position: static;
        position: initial;
        -webkit-transform: none;
                transform: none;
        margin-bottom: 15px; }
      .banner_intro__sub {
        position: static;
        position: initial;
        -webkit-transform: none;
                transform: none; }
      .banner_intro__text {
        bottom: 160px;
        top: auto;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
        white-space: nowrap; }
        .banner_intro__text .icon {
          -webkit-transform: rotateZ(90deg);
                  transform: rotateZ(90deg); }
          .banner_intro__text .icon img {
            width: 12px;
            height: 8px; } }
  @media (max-width: 768px) {
    .banner_intro__title {
      font-size: 20px; } }

.graphic_project {
  background: #5bcdcd;
  min-height: 100vh;
  padding-left: 84px;
  padding-right: 180px; }
  .graphic_project__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh; }
  .graphic_project__text {
    max-width: 300px; }
  .graphic_project__des {
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.07em;
    color: #ffffff;
    margin-bottom: 21px; }
  .graphic_project__cta {
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "MonumentExtended";
    background: none;
    border: none; }
  .graphic_project__svg {
    color: #5bcdcd;
    transition: color 2s ease-in-out;
    min-height: 280px; }
    .graphic_project__svg .svg__animate {
      display: none; }
    .graphic_project__svg .svg__desktop.animate__animated {
      display: block; }
  @media (max-width: 1250px) {
    .graphic_project__section2 {
      max-width: 490px; } }
  @media (max-width: 991px) {
    .graphic_project {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 80px;
      padding-bottom: 50px; }
      .graphic_project__container {
        display: block;
        min-height: auto; }
      .graphic_project__img {
        text-align: center;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 40px; }
      .graphic_project__text {
        margin: auto;
        margin-bottom: 50px;
        text-align: center; }
      .graphic_project__timeline .timeline__top {
        height: 40px;
        top: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .graphic_project__timeline .timeline__bottom {
        height: 40px;
        bottom: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .graphic_project__timeline .timeline__center {
        right: 50%;
        top: 50px;
        -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0);
        height: auto; }
      .graphic_project__svg .svg__desktop.animate__animated {
        display: none; }
      .graphic_project__svg .svg__mobile.animate__animated {
        display: block; } }

.ui_ux_project {
  background: #002038;
  padding-left: 0; }
  .ui_ux_project__img {
    display: flex;
    align-items: center;
    width: 100%; }
    .ui_ux_project__img__item {
      display: none !important; }
      .ui_ux_project__img__item.animate__animated {
        display: inline-block !important; }
  .ui_ux_project__left {
    background: #5bcdcd;
    width: 100%;
    max-width: 429px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .ui_ux_project__left img {
      display: inline-block;
      width: 406px;
      min-width: 406px; }
  .ui_ux_project__right {
    overflow: hidden;
    display: flex;
    width: 100%;
    align-items: center;
    height: 100vh; }
    .ui_ux_project__right img {
      display: inline-block;
      margin-left: -95px;
      margin-top: -15px;
      min-width: 454px; }
  @media (max-width: 1250px) {
    .ui_ux_project__left img {
      width: 284px;
      min-width: 284px; }
    .ui_ux_project__right img {
      margin-left: -75px;
      width: 317px;
      min-width: 317px; } }
  @media (max-width: 991px) {
    .ui_ux_project {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 80px;
      padding-bottom: 50px; }
      .ui_ux_project__container {
        display: block;
        min-height: auto; }
      .ui_ux_project__img {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px; }
      .ui_ux_project__left {
        max-width: none;
        max-width: initial;
        display: none; }
      .ui_ux_project__right {
        max-width: none;
        max-width: initial;
        height: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        justify-content: center; }
        .ui_ux_project__right img {
          margin: 0;
          width: auto;
          width: initial;
          min-width: 0;
          min-width: initial; }
      .ui_ux_project__text {
        margin: auto;
        margin-bottom: 50px;
        text-align: center; }
      .ui_ux_project__timeline .timeline__top {
        height: 40px;
        top: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .ui_ux_project__timeline .timeline__bottom {
        height: 40px;
        bottom: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .ui_ux_project__timeline .timeline__center {
        right: 50%;
        top: 50px;
        -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0);
        height: auto; } }

.ui_ux_detail .project_detail__info {
  background-color: #002038; }

.ui_ux_detail .project_detail__imgs {
  background-image: url(/public/img/ui_ux_project_detail_bg.jpg); }

.social_project {
  background-image: url(/public/img/social_project__bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-left: 0; }
  .social_project__imgs {
    display: flex;
    align-items: center;
    width: 100%; }
  .social_project__1 {
    background: #5bcdcd;
    width: 100%;
    max-width: 340px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px; }
    .social_project__1 img {
      display: inline-block; }
  .social_project__2 {
    background: #002038;
    width: 100%;
    max-width: 300px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px; }
    .social_project__2 img {
      display: inline-block; }
  .social_project__3 {
    background: transparent;
    width: 100%;
    max-width: 429px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px; }
    .social_project__3 img {
      display: inline-block; }
  .social_project__item {
    display: none !important; }
    .social_project__item.animate__animated {
      display: inline-block !important; }
  @media (max-width: 991px) {
    .social_project {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 80px;
      padding-bottom: 50px; }
      .social_project__container {
        display: block;
        min-height: auto; }
      .social_project__imgs .social_project__1,
      .social_project__imgs .social_project__2 {
        display: none; }
      .social_project__imgs .social_project__3 {
        min-height: 0;
        min-height: initial;
        height: inherit;
        max-width: 100%; }
      .social_project__text {
        margin: auto;
        margin-bottom: 50px;
        text-align: center; }
      .social_project__timeline .timeline__top {
        height: 40px;
        top: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .social_project__timeline .timeline__bottom {
        height: 40px;
        bottom: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .social_project__timeline .timeline__center {
        right: 50%;
        top: 50px;
        -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0);
        height: auto; } }

.social_detail .project_detail__info {
  background-image: url(/public/img/social_detail__bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }

.social_detail .project_detail__imgs {
  background-image: url(/public/img/social_detail__main_bg.jpg); }

.new_brand {
  background: #14182d;
  padding-left: 0; }
  .new_brand__imgs {
    display: flex;
    align-items: center;
    width: 100%; }
  .new_brand__1 {
    width: 100%;
    max-width: 340px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; }
    .new_brand__1 img {
      display: inline-block; }
  .new_brand__2 {
    width: 100%;
    max-width: 300px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; }
    .new_brand__2 img {
      display: inline-block; }
  .new_brand__3 {
    width: 100%;
    max-width: 429px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; }
    .new_brand__3 img {
      display: inline-block; }
  .new_brand__item {
    display: none !important; }
    .new_brand__item.animate__animated {
      display: inline-block !important; }
  @media (max-width: 991px) {
    .new_brand {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 80px;
      padding-bottom: 50px; }
      .new_brand__container {
        display: block;
        min-height: auto; }
      .new_brand__imgs .new_brand__1,
      .new_brand__imgs .new_brand__3 {
        display: none; }
      .new_brand__imgs .new_brand__2 {
        min-height: auto;
        height: auto;
        height: initial;
        padding-left: 5px;
        padding-right: 5px;
        max-width: inherit; }
      .new_brand__timeline .timeline__top {
        height: 40px;
        top: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .new_brand__timeline .timeline__bottom {
        height: 40px;
        bottom: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .new_brand__timeline .timeline__center {
        right: 50%;
        top: 50px;
        -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0);
        height: auto; } }

.beyond_creative {
  background: #0a0d1c; }
  .beyond_creative__imgs {
    display: flex;
    align-items: center;
    width: 100%; }
  .beyond_creative__title {
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: 0.1em;
    color: #ffffff;
    font-family: "MonumentExtended";
    margin-bottom: 30px; }
  .beyond_creative__download {
    border-radius: 6px;
    height: 45px;
    border: 2px solid #4069fd;
    padding: 11px 20px;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #ffffff;
    display: inline-block;
    margin-bottom: 80px;
    max-width: 240px; }
  .beyond_creative__contact {
    position: relative;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    letter-spacing: 0.07em; }
    .beyond_creative__contact .device {
      margin-left: 15px;
      margin-right: 15px; }
    .beyond_creative__contact::before, .beyond_creative__contact:after {
      content: "";
      opacity: 0.3;
      border: 1px solid #49abfd;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0px;
      left: 0; }
    .beyond_creative__contact::after {
      top: auto;
      bottom: 0; }
  .beyond_creative__timeline .timeline__top {
    height: 50vh; }
  .beyond_creative__timeline .timeline__center {
    width: 7px;
    height: 7px;
    background: #ffffff;
    border-radius: 100%; }
  .beyond_creative .graphic_project__des {
    margin-bottom: 0; }
  .beyond_creative__item {
    display: none !important; }
    .beyond_creative__item.animate__animated {
      display: block !important; }
  @media (max-width: 1250px) {
    .beyond_creative__title {
      font-size: 40px; } }
  @media (max-width: 991px) {
    .beyond_creative {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 110px;
      padding-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .beyond_creative__timeline .timeline__top {
        height: 40px;
        top: 0;
        right: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .beyond_creative__timeline .timeline__bottom {
        display: none; }
      .beyond_creative__timeline .timeline__center {
        right: calc(50% + 1px);
        top: 40px;
        -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0);
        display: none; }
      .beyond_creative__container {
        display: flex;
        align-items: center;
        justify-content: center; }
      .beyond_creative__text {
        position: absolute;
        top: 50px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .beyond_creative__contact {
        position: absolute;
        bottom: 40px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 100%; }
        .beyond_creative__contact::before, .beyond_creative__contact::after {
          display: none; }
        .beyond_creative__contact span {
          display: block;
          margin-bottom: 5px; }
        .beyond_creative__contact .device {
          display: none; }
      .beyond_creative__download {
        margin-left: auto;
        margin-right: auto; } }
  @media (max-width: 768px) {
    .beyond_creative__title {
      font-size: 20px; } }
